var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"combo-dialog",attrs:{"visible":_vm.visible,"title":_vm.dialogTitle,"width":"800px"},on:{"opened":_vm.afterOpen,"close":_vm.onClose}},[_c('div',{staticClass:"scroll-wrap"},[_c('el-form',{ref:"form",attrs:{"model":_vm.comboForm,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"方案名称","rules":[{ required: true, message: '方案名称不能为空' }],"prop":"comboName"}},[_c('el-input',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入方案名称","size":"small"},model:{value:(_vm.comboForm.comboName),callback:function ($$v) {_vm.$set(_vm.comboForm, "comboName", $$v)},expression:"comboForm.comboName"}})],1),_c('el-form-item',{attrs:{"label":"方案类型","prop":"comboType"}},[_c('el-select',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode || _vm.mode === 'edit',"placeholder":"请选择方案类型","size":"small"},model:{value:(_vm.comboForm.comboType),callback:function ($$v) {_vm.$set(_vm.comboForm, "comboType", $$v)},expression:"comboForm.comboType"}},_vm._l((_vm.dict.ComboTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"费率","prop":"priceRate","rules":[{ type: 'number', required: true, message: '费率不能为空' }]}},[_c('el-input-number',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入费率","controls":false,"min":0,"max":130,"precision":0,"size":"small"},model:{value:(_vm.comboForm.priceRate),callback:function ($$v) {_vm.$set(_vm.comboForm, "priceRate", $$v)},expression:"comboForm.priceRate"}}),_vm._v(" % ")],1),_c('el-form-item',{attrs:{"label":"总期数","prop":"billNum","rules":[
          { type: 'number', required: true, message: '总期数不能为空' },
        ]}},[_c('el-input-number',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入总期数","controls":false,"min":4,"max":24,"precision":0,"size":"small"},model:{value:(_vm.comboForm.billNum),callback:function ($$v) {_vm.$set(_vm.comboForm, "billNum", $$v)},expression:"comboForm.billNum"}})],1),_c('el-form-item',{attrs:{"label":"首付期数","prop":"prepayBillNum","rules":[
          { type: 'number', required: true, message: '总期数不能为空' },
        ]}},[_c('el-input-number',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入首付期数","controls":false,"min":1,"precision":0,"size":"small"},model:{value:(_vm.comboForm.prepayBillNum),callback:function ($$v) {_vm.$set(_vm.comboForm, "prepayBillNum", $$v)},expression:"comboForm.prepayBillNum"}})],1),_c('el-form-item',{attrs:{"label":"首付比例","prop":"prepayRate","rules":[
          { type: 'number', required: true, message: '首付比例不能为空' },
        ]}},[_c('el-input-number',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入首付比例","controls":false,"min":0,"max":100,"precision":0,"size":"small"},model:{value:(_vm.comboForm.prepayRate),callback:function ($$v) {_vm.$set(_vm.comboForm, "prepayRate", $$v)},expression:"comboForm.prepayRate"}}),_vm._v(" % ")],1),_c('el-form-item',{attrs:{"label":"方案等级"}},[_c('el-radio-group',{attrs:{"disabled":_vm.isViewMode},model:{value:(_vm.comboForm.comboGrade),callback:function ($$v) {_vm.$set(_vm.comboForm, "comboGrade", $$v)},expression:"comboForm.comboGrade"}},_vm._l((_vm.dict.ComboGradeList),function(item){return _c('el-radio',{key:item.value,attrs:{"label":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('div',{staticClass:"repay-wrap"},[_c('div',{staticClass:"repay-title"},[_vm._v("还款计划")]),_c('div',{staticClass:"repay-form"},_vm._l((_vm.comboForm.repayConfigList),function(repay,index){return _c('div',{staticClass:"repay-form-item"},[_c('el-form-item',{attrs:{"label-width":"0px","prop":'repayConfigList.' + index + '.isPrepay'}},[_c('el-checkbox',{attrs:{"disabled":_vm.isViewMode},model:{value:(repay.isPrepay),callback:function ($$v) {_vm.$set(repay, "isPrepay", $$v)},expression:"repay.isPrepay"}},[_vm._v("第"+_vm._s(index + 1)+"期")])],1),_c('el-form-item',{attrs:{"label-width":"0px","prop":'repayConfigList.' + index + '.repayRate',"rules":[
                {
                  type: 'number',
                  required: true,
                  message: '还款比例不能为空',
                },
              ]}},[_c('el-input-number',{staticClass:"combo-form-item",attrs:{"disabled":_vm.isViewMode,"placeholder":"请输入还款比例","controls":false,"min":0,"max":100,"precision":0,"size":"small"},model:{value:(repay.repayRate),callback:function ($$v) {_vm.$set(repay, "repayRate", $$v)},expression:"repay.repayRate"}}),_vm._v(" % ")],1)],1)}),0)])],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),(!_vm.isViewMode)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确 定")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }