<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name" />
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="方案名称">
          <el-input
            v-model="searchData.comboName"
            size="small"
            placeholder="请输入"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="方案类型">
          <el-select
            v-model="searchData.comboType"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option
              v-for="item in dict.ComboTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="总期数">
          <el-input-number
            v-model="searchData.billNum"
            size="small"
            placeholder="请输入"
            :controls="false"
            :min="1"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="方案等级">
          <el-select
            v-model="searchData.comboGrade"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option
              v-for="item in dict.ComboGradeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="create-combo">
      <el-button
        type="primary"
        size="small"
        @click="openDialog('create')"
        :disabled="!isShowBtn(AUTH_BTN.store_combo_create)"
        >新增方案</el-button
      >
    </div>
    <el-tabs v-model="searchData.status" extra>
      <el-tab-pane label="全部" name="All" />
      <el-tab-pane
        v-for="status in dict.ComboStatusList"
        :label="status.name"
        :name="status.value.toString()"
        :key="status.value"
      />
    </el-tabs>
    <tp-table
      :tableData="table.data"
      :totalNum="table.total"
      :current-page.sync="table.currentPage"
      :pageSize.sync="table.pageSize"
    >
      <el-table-column prop="id" label="ID" width="55px" />
      <el-table-column prop="comboName" label="方案名称" :min-width="180" />
      <el-table-column prop="comboTypeName" label="方案类型" />
      <el-table-column prop="comboGrade" label="方案等级">
        <template slot-scope="scope">
          <span>{{ scope.row.comboGrade }}级</span>
        </template>
      </el-table-column>
      <el-table-column prop="billNum" label="总期数">
        <template slot-scope="scope">
          <span>{{ scope.row.billNum }}期</span>
        </template>
      </el-table-column>
      <el-table-column prop="prepayBillNum" label="首付期数">
        <template slot-scope="scope">
          <span>{{ scope.row.prepayBillNum }}期</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.status"
            @change="onStatusChange(scope.row)"
            :disabled="!isShowBtn(AUTH_BTN.store_combo_status_edit)"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <div class="operations">
            <el-link
              type="primary"
              @click="openDialog('view', scope.row.id)"
              :underline="false"
              :disabled="!isShowBtn(AUTH_BTN.store_combo_view)"
              >详情</el-link
            >
            <el-link
              type="primary"
              @click="openDialog('edit', scope.row.id)"
              :underline="false"
              v-if="!scope.row.status"
              :disabled="!isShowBtn(AUTH_BTN.store_combo_edit)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              @click="onDelete(scope.row)"
              :underline="false"
              v-if="!scope.row.status"
              :disabled="!isShowBtn(AUTH_BTN.store_combo_delete)"
              >删除</el-link
            >
          </div>
        </template>
      </el-table-column>
    </tp-table>
    <store-combo-detail
      :visible.sync="dialogConfig.visible"
      :id="dialogConfig.id"
      :mode="dialogConfig.mode"
      @refreshList="getList"
    />
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
  ComboTypeList,
  ComboGradeList,
  ComboStatusList,
} from "@/enum/dict/index.js";

import { getComboList, deleteCombo, editComboStatus } from "./api.js";
import StoreComboDetail from "./detail.vue";

export default {
  name: "StoreComboList",
  mixins: [authBtnMixin],
  components: {
    StoreComboDetail,
  },
  data() {
    return {
      searchData: {
        comboType: undefined,
        billNum: undefined,
        comboGrade: undefined,
        status: "All",
      },
      dict: { ComboTypeList, ComboGradeList, ComboStatusList },
      table: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        data: [],
      },
      dialogConfig: {
        mode: "view",
        id: null,
        visible: false,
      },
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    "table.currentPage"() {
      this.getList();
    },
    "page.pageSize"() {
      this.getList();
    },
    "searchData.status"() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const res = await getComboList({
        ...this.searchData,
        page: this.table.currentPage,
        pageSize: this.table.pageSize,
        status:
          this.searchData.status === "All" ? null : this.searchData.status,
      });

      this.table.data = res.list || [];
      this.table.total = res.total || 0;
    },
    reset() {
      this.$set(this, "searchData", this.$options.data().searchData);

      this.getList();
    },
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该方案, 是否继续?");

      await deleteCombo({
        id: row.id,
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.getList();
    },
    async onStatusChange(row) {
      await editComboStatus({
        id: row.id,
        status: !row.status,
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.getList();
    },
    openDialog(mode, id = null) {
      this.dialogConfig = { mode, id, visible: true };
    },
  },
};
</script>

<style lang="scss" scoped>
.search-form-item {
  width: 220px;

  /deep/ {
    input {
      text-align: left;
    }
  }
}

.create-combo {
  display: flex;
  justify-content: flex-end;
}

.operations {
  display: flex;
  gap: 8px;
}
</style>
