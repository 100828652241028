<template>
  <el-dialog
    :visible="visible"
    :title="dialogTitle"
    width="800px"
    class="combo-dialog"
    @opened="afterOpen"
    @close="onClose"
  >
    <div class="scroll-wrap">
      <el-form ref="form" :model="comboForm" label-width="120px">
        <el-form-item
          label="方案名称"
          :rules="[{ required: true, message: '方案名称不能为空' }]"
          prop="comboName"
        >
          <el-input
            v-model="comboForm.comboName"
            :disabled="isViewMode"
            placeholder="请输入方案名称"
            class="combo-form-item"
            size="small"
          />
        </el-form-item>
        <el-form-item label="方案类型" prop="comboType">
          <el-select
            v-model="comboForm.comboType"
            :disabled="isViewMode || mode === 'edit'"
            placeholder="请选择方案类型"
            class="combo-form-item"
            size="small"
          >
            <el-option
              v-for="item in dict.ComboTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="费率"
          prop="priceRate"
          :rules="[{ type: 'number', required: true, message: '费率不能为空' }]"
        >
          <el-input-number
            v-model="comboForm.priceRate"
            :disabled="isViewMode"
            placeholder="请输入费率"
            :controls="false"
            :min="0"
            :max="130"
            :precision="0"
            class="combo-form-item"
            size="small"
          />
          %
        </el-form-item>
        <el-form-item
          label="总期数"
          prop="billNum"
          :rules="[
            { type: 'number', required: true, message: '总期数不能为空' },
          ]"
        >
          <el-input-number
            v-model="comboForm.billNum"
            :disabled="isViewMode"
            placeholder="请输入总期数"
            :controls="false"
            :min="4"
            :max="24"
            :precision="0"
            class="combo-form-item"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="首付期数"
          prop="prepayBillNum"
          :rules="[
            { type: 'number', required: true, message: '总期数不能为空' },
          ]"
        >
          <el-input-number
            v-model="comboForm.prepayBillNum"
            :disabled="isViewMode"
            placeholder="请输入首付期数"
            :controls="false"
            :min="1"
            :precision="0"
            class="combo-form-item"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="首付比例"
          prop="prepayRate"
          :rules="[
            { type: 'number', required: true, message: '首付比例不能为空' },
          ]"
        >
          <el-input-number
            v-model="comboForm.prepayRate"
            :disabled="isViewMode"
            placeholder="请输入首付比例"
            :controls="false"
            :min="0"
            :max="100"
            :precision="0"
            class="combo-form-item"
            size="small"
          />
          %
        </el-form-item>
        <el-form-item label="方案等级">
          <el-radio-group v-model="comboForm.comboGrade" :disabled="isViewMode">
            <el-radio
              v-for="item in dict.ComboGradeList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="repay-wrap">
          <div class="repay-title">还款计划</div>
          <div class="repay-form">
            <div
              v-for="(repay, index) in comboForm.repayConfigList"
              class="repay-form-item"
            >
              <el-form-item
                label-width="0px"
                :prop="'repayConfigList.' + index + '.isPrepay'"
              >
                <el-checkbox v-model="repay.isPrepay" :disabled="isViewMode"
                  >第{{ index + 1 }}期</el-checkbox
                >
              </el-form-item>

              <el-form-item
                label-width="0px"
                :prop="'repayConfigList.' + index + '.repayRate'"
                :rules="[
                  {
                    type: 'number',
                    required: true,
                    message: '还款比例不能为空',
                  },
                ]"
              >
                <el-input-number
                  v-model="repay.repayRate"
                  :disabled="isViewMode"
                  placeholder="请输入还款比例"
                  :controls="false"
                  :min="0"
                  :max="100"
                  :precision="0"
                  class="combo-form-item"
                  size="small"
                />
                %
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button v-if="!isViewMode" type="primary" @click="onSubmit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  EnumComboType,
  ComboTypeList,
  EnumComboGrade,
  ComboGradeList,
} from "@/enum/dict/index.js";
import { getComboDetail, createCombo, editCombo } from "./api.js";

export default {
  name: "StoreComboDetail",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: true,
      validator: (value) => ["create", "edit", "view"].includes(value),
    },
  },
  data() {
    return {
      comboForm: {
        id: null,
        comboName: "",
        comboType: EnumComboType.COMMON,
        billNum: 6,
        comboGrade: EnumComboGrade.A,
        prepayBillNum: 1,
        repayConfigList: [],
      },
      dict: { ComboTypeList, ComboGradeList },
    };
  },
  computed: {
    dialogTitle() {
      switch (this.mode) {
        case "create":
          return "新增方案";
        case "edit":
          return "编辑方案";
        case "view":
          return "方案详情";
        default:
          return "";
      }
    },
    isViewMode() {
      return this.mode === "view";
    },
  },
  watch: {
    "comboForm.billNum"(newVal) {
      this.generateRepayConfigList(newVal);
    },
  },
  methods: {
    async afterOpen() {
      if (this.mode === "create") {
        this.$refs.form.resetFields();

        this.$set(this, "comboForm", this.$options.data().comboForm);

        this.generateRepayConfigList(this.comboForm.billNum);
        return;
      }

      const res = await getComboDetail({ id: this.id });

      this.comboForm = { ...res };
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      if (this.comboForm.id) {
        await editCombo(this.comboForm);
      } else {
        await createCombo(this.comboForm);
      }

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.onClose();

      this.$emit("refreshList");
    },
    generateRepayConfigList(billNum) {
      const currentLength = this.comboForm.repayConfigList.length;

      if (billNum < currentLength) {
        this.comboForm.repayConfigList.splice(billNum, currentLength - billNum);
      }

      if (billNum > currentLength) {
        this.comboForm.repayConfigList.push(
          ...new Array(billNum - currentLength).fill(0).map(() => {
            return {
              isPrepay: false,
              repayRate: undefined,
            };
          }),
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.combo-dialog {
  /deep/ .el-form-item__label {
    font-weight: bold;
  }
}

.scroll-wrap {
  max-height: 70vh;
  overflow-y: auto;
}

.combo-form-item {
  width: 300px;

  /deep/ input {
    text-align: left;
  }
}

.repay-wrap {
  .repay-title {
    width: 120px;
    line-height: 40px;
    padding-right: 12px;
    text-align: right;
    font-weight: bold;
  }

  .repay-form {
    padding: 0 50px;
  }

  .repay-form-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 14px 0;

    /deep/ .el-form-item {
      margin: 0;
    }

    &:first-child {
      border-top: 1px solid #ddd;
    }
  }
}
</style>
